<template>
	<v-sheet class="main-detail">
		<div class="">
			<v-layout class="section-main-content">
				<div class="listing-sidebar border-right" style="height: calc(100vh - 56px)">
					<div class="listing-header d-flex align-center py-0">
						<v-menu offset-y right bottom content-class="elevation-3" rounded="0">
							<template v-slot:activator="{ on, attrs }">
								<p class="sub-heading m-0" v-on="on" v-bind="attrs">
									All Task
									<v-icon>mdi-menu-down</v-icon>
								</p>
							</template>
							<v-list>
								<v-list-item-group>
									<v-list-item
										v-for="(status, index) in listingStatus"
										:key="index"
										v-on:click="statusFilter(status.value)"
									>
										<v-list-item-title class="d-flex align-items-center">
											<!-- {{ status }} -->
											<span> {{ status.text }}</span>
											<v-spacer></v-spacer>
											<div class="cm-budge white--text" :class="status.status_color">
												{{ status.status_count }}
											</div>
										</v-list-item-title>
									</v-list-item>
								</v-list-item-group>
							</v-list>
						</v-menu>
						<v-spacer></v-spacer>
						<!-- <v-btn
							depressed
							tile
							color="blue darken-4"
							class="white--text mr-2"
							@click="search_open = !search_open"
						>
							<v-icon class="icon-16px"> fa fa-search </v-icon></v-btn
						> -->
						<!-- <v-btn
							depressed
							tile
							color="blue darken-4"
							class="white--text"
							v-on:click="open_task_drawer('Create')"
						>
							<v-icon>mdi-plus</v-icon> New</v-btn
						> -->
						<div class="mr-2 my-1 fw-600">
							Current Records
							<v-chip color="primary" outlined class="ml-1">{{ statusData.length }}</v-chip>
						</div>
					</div>

					<div class="side-listing listing-sidebar-list">
						<div class="listing-innerlist" style="height: calc(100vh - 150px); overflow-y: auto">
							<div
								v-for="(data, index) in statusData"
								:key="index"
								:class="`list-items ${
									data.id == taskId ? `active ${getStatusActiveColor(data.status)}` : ''
								}`"
								@click="tabDetails(data.id, data.status)"
							>
								<template>
									<v-tooltip
										:color="data.priority_color"
										top
										:content-class="` ${getActiveColor(data.priority)} custom-top-tooltip`"
									>
										<template v-slot:activator="{ on, attrs }">
											<div :class="`labeltag ${getActiveColor(data.priority)}`" v-on="on" v-bind="attrs"></div>
										</template>
										<span>{{ data.priority }}</span>
									</v-tooltip>
								</template>

								<div class="d-flex">
									<div class="ellipsis fw-500 mr-2 w-80">
										<span class="fw-600 pr-2">Task # : </span>
										<template v-if="data && data.barcode">
											<span class="fw-500">{{ data.barcode }}</span>
										</template>
										<template v-else>no barcode</template>
									</div>
									<div class="ellipsis fw-500 text-end w-20">
										<template v-if="data && data.related_to">
											<span class="fw-500">
												<v-chip
													class="ml-2 text-capitalize"
													color="red white--text"
													x-small
													label
													v-if="data && data.related_to"
													>{{ data.related_to.replace("-", " ") }}</v-chip
												></span
											>
										</template>
									</div>
								</div>
								<div class="ellipsis pr-6" v-if="data && data.title">
									<div class="ellipsis pr-6">
										<span class="fw-600 pr-2">Title : </span>
										<span class="fw-500" v-if="data && data.title">{{ data.title }}</span>
										<em class="text-muted" v-else> no title</em>
									</div>
								</div>
								<div class="ellipsis pr-6" v-if="data && data.lead_barcode && data.related_to == 'lead'">
									<div class="ellipsis pr-6">
										<span class="fw-600 pr-2">Lead # : </span>
										<template v-if="data && data.lead_barcode">
											<span class="fw-500">{{ data.lead_barcode }}</span>
										</template>
									</div>
								</div>
								<div class="ellipsis pr-6" v-if="data && data.related && data.related_to == 'lead'">
									<div class="ellipsis pr-6">
										<span class="fw-600 pr-2">Company Name : </span>
										<template v-if="data && data.related">
											<span class="fw-500">{{ data.related }}</span>
										</template>
										<em class="text-muted" v-else> no company name </em>
									</div>
								</div>
								<div
									class="ellipsis pr-6"
									v-if="data && data.lead_customer_name && data.related_to == 'lead'"
								>
									<div class="ellipsis pr-6">
										<span class="fw-600 pr-2">Person Incharge : </span>
										<template v-if="data && data.lead_customer_name">
											<span class="fw-500">{{ data.lead_customer_name }}</span>
										</template>
										<em class="text-muted" v-else> no person incharge </em>
									</div>
								</div>
								<div class="ellipsis pr-6" v-if="data && data.lead_date && data.related_to == 'lead'">
									<div class="ellipsis pr-6">
										<span class="fw-600 pr-2">Lead Date : </span>
										<template v-if="data && data.lead_date">
											<span class="fw-500">{{ formatDate(data.lead_date) }}</span>
										</template>
										<em class="text-muted" v-else> no lead date </em>
									</div>
								</div>
								<div class="ellipsis pr-6">
									<div class="ellipsis pr-6">
										<span class="fw-600 pr-2">Start Date : </span>
										<span class="fw-500" v-if="data && data.start_date">{{
											formatDate(data.start_date)
										}}</span>
										<span v-else> no start date</span>
									</div>
								</div>

								<div class="ellipsis pr-6">
									<div class="ellipsis pr-6">
										<span class="fw-600 pr-2">Due Date : </span>
										<span class="fw-500" v-if="data && data.due_date">{{ formatDate(data.due_date) }}</span>
										<span v-else> no due date</span>
									</div>
								</div>
								<div class="ellipsis pr-6">
									<span class="fw-600 pr-2">Attendees : </span>
									<template
										v-if="
											data && data.attendies[0] && data.attendies[0].display_name && data.attendies.length
										"
									>
										<span class="fw-500"
											>({{ data.attendies[0].display_name }})
											<template v-if="data.attendies.length > 1">
												+{{ data.attendies.length - 1 }} More
											</template></span
										>
									</template>
									<em class="text-muted" v-else>no attendees</em>
								</div>
							</div>
						</div>
					</div>
					<div class="text-center my-2" v-if="showLoadMoreBtn()">
						<v-btn
							color="primary"
							@click="
								() => {
									if (pageLoading) {
										return false;
									} else {
										loadMoreTasks();
									}
								}
							"
						>
							<template v-if="pageLoading">Loading...</template>
							<template v-else> <v-icon left>mdi-reload</v-icon>Load more </template>
						</v-btn>
					</div>
					<!-- customer details -->

					<!-- customer details end -->
				</div>
				<div class="details-body">
					<div class="p-sticky bg-white">
						<v-layout class="align-center light-blue-bg px-4 py-2 flex-wrap">
							<v-flex md12>
								<div class="d-flex">
									<v-chip
										class="mr-1 text-capitalize"
										color="cyan white--text"
										x-small
										v-if="this.singleData && this.singleData.barcode"
										>{{ this.singleData.barcode }}
									</v-chip>
									<p class="my-auto font-level-3-bold">
										<v-chip class="mb-1 mr-1" label :color="`${getStatusColor()} white--text`" x-small>
											{{ getStatusText() }}
										</v-chip>
									</p>
									<p class="my-auto font-level-3-bold text-capitalize">
										<v-chip class="mb-1 mr-1" label color="red white--text" x-small>
											{{ singleData.related_to }}
										</v-chip>
									</p>
									<v-spacer></v-spacer>
									<!-- <v-icon
										style="font-size: 30px; margin-top: -6px"
										color="green"
										small
										class="mr-2"
										v-on:click="taskDialog = true"
										>mdi-chat
									</v-icon> -->
									<v-menu offset-y left bottom content-class="elevation-3" rounded="0">
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												color="blue darken-4 text-white"
												v-on="on"
												v-bind="attrs"
												class="mr-2"
												depressed
												tile
												><v-icon small left>mdi-more mdi-flip-h</v-icon>More</v-btn
											>
											<v-btn
												v-if="getPermission('task:update')"
												color="blue darken-4 text-white"
												class="mr-2"
												depressed
												tile
												v-on:click="doAction('edit')"
												><v-icon small left>mdi-more mdi-flip-h</v-icon>Edit</v-btn
											>
										</template>

										<v-list>
											<!-- <v-list-item v-on:click="doAction('edit')">
												<v-list-item-title>
													<v-icon small left color="blue darken-4">mdi-pencil</v-icon>
													Edit</v-list-item-title
												>
											</v-list-item> -->
											<v-list-item v-on:click="doAction('mark_task_progress')">
												<v-list-item-title v-if="singleData.status != 1">
													<v-icon small left color="blue darken-4">mdi-cancel</v-icon>
													Mark As In-Progress</v-list-item-title
												>
											</v-list-item>
											<v-list-item v-on:click="doAction('mark_task_completed')">
												<v-list-item-title v-if="singleData.status != 2">
													<v-icon small left color="blue darken-4">mdi-cancel</v-icon>
													Mark As Complete</v-list-item-title
												>
											</v-list-item>
											<v-list-item v-on:click="doAction('mark_task_pending')">
												<v-list-item-title v-if="singleData.status != 3">
													<v-icon small left color="blue darken-4">mdi-orbit-variant</v-icon>
													Mark As Pending</v-list-item-title
												>
											</v-list-item>
										</v-list>
									</v-menu>
									<div v-if="taskTab == 'overview'">
										<v-btn
											depressed
											tile
											class="blue darken-4 my-auto"
											color="white--text "
											v-on:click="open_dialog.create_task = true"
										>
											<v-icon class="mr-1">mdi-plus</v-icon>
											Create
										</v-btn>
									</div>
									<!-- <div v-if="taskTab == 'activity'">
										<v-btn depressed tile class="blue darken-4 mr-2 my-auto" color="white--text ">
											<v-icon class="mr-1">mdi-account-group-outline</v-icon>
											All Users
										</v-btn>
									</div> -->
									<!-- <div v-if="taskTab == 'notes'">
										<v-btn
											depressed
											tile
											class="blue darken-4 mr-2 my-auto"
											color="white--text "
											@click="open_dialog.notes = true"
										>
											<v-icon class="mr-1">mdi-plus</v-icon>
											Create
										</v-btn>
									</div> -->
									<div v-if="taskTab == 'task'">
										<v-btn
											depressed
											tile
											class="blue darken-4 mr-2 my-auto"
											color="white--text "
											@click="open_dialog.task = true"
										>
											<v-icon class="mr-1">mdi-plus</v-icon>
											Create Task
										</v-btn>
									</div>
									<div v-if="taskTab == 'meeting'">
										<v-btn
											v-if="getPermission('meeting:create')"
											depressed
											tile
											class="blue darken-4 mr-2 my-auto"
											color="white--text "
											@click="open_meeting_drawer('Create')"
										>
											<v-icon class="mr-1">mdi-plus</v-icon>
											Create Meeting
										</v-btn>
									</div>
									<div v-if="taskTab == 'reminder'">
										<v-btn
											v-if="getPermission('reminder:create')"
											depressed
											tile
											class="blue darken-4 mr-2 my-auto"
											color="white--text "
											@click="create_reminder"
										>
											<v-icon class="mr-1">mdi-plus</v-icon>
											Create Reminder
										</v-btn>
									</div>
									<v-btn depressed tile class="my-auto" v-on:click="goBackData()">
										<v-icon class="mr-1">mdi-keyboard-backspace</v-icon>
										back
									</v-btn>
								</div>
								<div>
									<div class="row" v-if="singleData.lead_barcode && singleData.related_to == 'lead'">
										<div class="mt-2 py-0 col-md-3 col-12">
											<span class="fw-600 mr-2">Lead # : </span>
											<span class="fw-500" v-if="singleData && singleData.lead_barcode">
												{{ singleData.lead_barcode }}</span
											>
										</div>
										<div class="d-flex mt-2 py-0 col-md-3 col-12">
											<span class="fw-600 mr-2">Person Incharge </span>
											<template v-if="singleData && singleData.lead_customer_name">
												<span class="fw-500" v-if="singleData && singleData.lead_customer_name">
													{{ singleData.lead_customer_name }}</span
												>
											</template>
											<template v-else> no person incharge </template>
										</div>
										<div class="mt-2 py-0 col-md-3 col-12">
											<span class="fw-600 mr-2">Company Name : </span>
											<template v-if="singleData && singleData.related">
												<span class="fw-500" v-if="singleData && singleData.related">
													{{ singleData.related }}</span
												>
											</template>
											<template v-else> no company name</template>
										</div>
										<div class="mt-2 py-0 col-md-3 col-12">
											<span class="fw-600 mr-2">Lead Date : </span>
											<template v-if="singleData && singleData.lead_date">
												<span class="fw-500">{{ formatDate(singleData.lead_date) }}</span>
											</template>
											<template v-else> no lead date</template>
										</div>
									</div>

									<div class="row">
										<div class="mt-2 py-0 col-md-3 col-12">
											<span class="fw-600 mr-2">Title : </span>
											<span class="fw-500" v-if="singleData && singleData.title"> {{ singleData.title }}</span>
										</div>
										<div class="d-flex mt-2 py-0 col-md-3 col-12">
											<span class="fw-600 mr-2">Priority : </span>
											<template v-if="singleData && singleData.priority">
												<v-chip
													class="mr-1"
													label
													:color="`${getActiveColor(singleData.priority)} white--text text-capitalize`"
													x-small
												>
													{{ singleData.priority }}
												</v-chip>
											</template>
											<template v-else> no priority </template>
										</div>
										<div class="mt-2 py-0 col-md-3 col-12">
											<span class="fw-600 mr-2">Start Date : </span>
											<template v-if="singleData && singleData.start_date">
												<span class="fw-500">{{ formatDate(singleData.start_date) }}</span>
											</template>
											<template v-else> no start date</template>
										</div>
										<div class="mt-2 py-0 col-md-3 col-12">
											<span class="fw-600 mr-2">Due Date : </span>
											<template v-if="singleData && singleData.due_date">
												<span class="fw-500">{{ formatDate(singleData.due_date) }}</span>
											</template>
											<template v-else> no due date</template>
										</div>
									</div>

									<div class="row">
										<!-- <div
											class="mt-2 py-0 col-md-3 col-12 d-flex"
											v-if="singleData && singleData.related && singleData.related_to == 'lead'"
										>
											<span class="fw-600 mr-2">Company Name : </span>
											<template v-if="singleData && singleData.related">
												<span class="fw-500">{{ singleData.related }}</span>
											</template>
											<em class="text-muted" v-else> no company name</em>
										</div> -->

										<div class="mt-2 py-0 col-md-3 col-12">
											<span class="fw-600 mr-2">Reminder Date : </span>
											<template v-if="remindersdata && remindersdata[0] && remindersdata[0].reminder_date">
												<span class="fw-500">{{ formatDateTime(remindersdata[0].reminder_date) }}</span>
											</template>
											<template v-else> no reminder date</template>
										</div>
										<!-- 	<div
											class="mt-2 py-0 col-md-3 col-12"
											v-if="
												remindersdata &&
												remindersdata[0] &&
												remindersdata[0].reminder_end_date &&
												remindersdata[0].reminder_type != 1
											"
										>
											<span class="fw-600 mr-2">Reminder End Date : </span>
											<span
												class="fw-500"
												v-if="remindersdata && remindersdata[0] && remindersdata[0].reminder_end_date"
											>
												{{ formatDate(remindersdata[0].reminder_end_date) }}
											</span>
											<span v-else> no reminder end date</span>
										</div> -->
										<div class="mt-2 py-0 col-md-3 col-12">
											<span class="fw-600 mr-2">Reminder Type : </span>
											<span
												class="fw-500"
												v-if="remindersdata && remindersdata[0] && remindersdata[0].reminder_type"
											>
												{{ getReminderType(remindersdata[0].reminder_type) }}
											</span>
											<span v-else> no reminder Type</span>
										</div>

										<div class="mt-2 py-0 col-md-3 col-12 d-flex">
											<span class="fw-600 mr-2">Assigned To : </span>
											<span
												class="fw-500"
												v-if="attendieList && attendieList.attendies && attendieList.attendies.length"
											>
												<v-chip
													class="mb-1 mr-1"
													small
													v-for="(data, index) in attendieList.attendies"
													:key="index"
													><v-avatar left small>
														<img v-if="data.profile_img" :src="data.profile_img" />
														<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
													</v-avatar>
													<span v-if="data && data.display_name">{{ data.display_name }}</span>
												</v-chip>
											</span>
											<span v-else> no assign</span>
										</div>
									</div>

									<!-- <div class="row">
										
										<div class="mt-2 py-0 col-md-3 col-12 d-flex">
											<span class="fw-600 mr-2">Assigned To : </span>
											<span
												class="fw-500"
												v-if="attendieList && attendieList.attendies && attendieList.attendies.length"
											>
												<v-chip
													class="mb-1 mr-1"
													x-small
													v-for="(data, index) in attendieList.attendies"
													:key="index"
												>
													<template v-if="data && data.first_name">{{ data.first_name }}</template>
												</v-chip>
											</span>
											<span v-else> no assign</span>
										</div>
									</div> -->

									<!-- <div class="row">
										<div class="mt-2 py-0 col-md-3 col-12">
											<span class="fw-600 mr-2">Description : </span>
											<template v-if="singleData && singleData.description">
												<span class="fw-500">{{ singleData.description }}</span>
											</template>
											<template v-else> no description</template>
										</div>
									</div> -->
								</div>
							</v-flex>
						</v-layout>
						<v-layout class="px-4 py-2">
							<v-tabs
								v-model="taskTab"
								background-color="transparent"
								color="blue"
								class="custom-tab-transparent w-100"
								active-class="blue darken-4 text-white"
								hide-slider
							>
								<v-tab href="#notes" v-if="false" v-on:click="getNotes()"
									><v-icon small left>mdi-note-plus-outline</v-icon>Notes</v-tab
								>
								<v-tab href="#checklist"> <v-icon small left>mdi-list-status</v-icon>Checklist</v-tab>
								<v-tab href="#meeting" v-on:click="getTaskMeeting()">
									<v-icon small left>mdi-timeline-clock-outline</v-icon>Meeting</v-tab
								>
								<v-tab href="#reminder" v-on:click="getReminder()">
									<v-icon small left>mdi-alarm-check</v-icon>Reminder</v-tab
								>
								<v-tab href="#file"><v-icon small left>mdi-file-document-multiple</v-icon>Files</v-tab>
								<v-tab href="#activity" v-on:click="taskActivity()"
									><v-icon small left>mdi-wrench-outline</v-icon>Activity</v-tab
								>
							</v-tabs>
						</v-layout>
					</div>
					<div class="tab-layout-content px-4">
						<v-tabs-items v-model="taskTab">
							<v-tab-item value="notes">
								<AllNotesTab
									:relatedId="taskId"
									:relatedType="'task-notes'"
									create-url="task-note"
									get-url="task-note"
									:singleData="singleData"
								>
								</AllNotesTab>
							</v-tab-item>
							<v-tab-item value="checklist">
								<div>
									<!-- <div class="py-3">
										<div class="fs-16 fw-500 primary--text">April 28</div>
									</div> -->
									<div>
										<ChecklistListing
											:task-id="taskId"
											:task-type="'task'"
											:dbNotes="checklists"
											v-on:updateTask="getTaskChecklist"
										></ChecklistListing>
									</div>
								</div>
							</v-tab-item>
							<v-tab-item value="meeting">
								<div v-if="meetingData && meetingData.length">
									<!-- <div class="py-3">
										<div class="fs-16 fw-500 primary--text">April 28</div>
									</div> -->
									<div v-for="(meetings, index) in meetingData" :key="index">
										<MeetingTab
											:open_details="!index ? true : false"
											v-on:editMeeting="editMeeting($event)"
											v-on:editTitle="open_meeting_drawer($event)"
											v-on:deleteMeeting="getTaskMeeting"
											:index-meeting="index"
											:meeting="meetings"
										></MeetingTab>
									</div>
								</div>
								<template v-else>
									<p class="m-0 row-not-found text-center">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There are no meeting at the moment.
									</p>
								</template>
							</v-tab-item>
							<v-tab-item value="reminder">
								<div v-if="reminderData && reminderData.length">
									<!-- <div class="py-3">
										<div class="fs-16 fw-500 primary--text">April 28</div>
									</div> -->
									<div v-for="(reminder, index) in reminderData" :key="index">
										<ReminderTab
											:open_details="!index ? true : false"
											:remind="reminder"
											:index-reminder="index"
											v-on:reminderSuccess="refreshReminder"
											v-on:editReminder="editReminderForm($event)"
										>
										</ReminderTab>
									</div>
								</div>
								<template v-else>
									<p class="m-0 row-not-found text-center">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There are no reminders at the moment.
									</p>
								</template>
							</v-tab-item>
							<v-tab-item value="file">
								<Files v-if="taskTab == 'file'" class="mx-4" type="task" :url="'task'" :type-id="taskId">
								</Files>
							</v-tab-item>

							<v-tab-item value="activity">
								<div>
									<div class="py-3">
										<div class="fs-16 fw-500 primary--text">Upcoming</div>
									</div>
									<div v-for="(logs, index) in logData" :key="index">
										<ActivityTab :open_details="true" :log="logs" title="Task"></ActivityTab>
									</div>
								</div>
							</v-tab-item>
						</v-tabs-items>
					</div>
				</div>
			</v-layout>
			<v-file-input ref="uploadFile" class="d-none" v-on:change="uploadFile($event)"></v-file-input>
		</div>
		<!-- sidebar drawers -->
		<CreateNote
			:drawer="open_dialog.notes"
			v-on:close="open_dialog.notes = false"
			v-on:updateSuccess="updateSuccessNote"
			:get-note-data="editNoteData"
			related="task"
			:relatedId="taskId"
		></CreateNote>
		<CreateMeeting
			v-if="open_dialog.meeting"
			:drawer="open_dialog.meeting"
			:title-status="meeting_title"
			:get-meeting-data="editMeetingData"
			:attendies="attendieList"
			v-on:updateMeetingSuccess="refreshMeeting"
			v-on:close="open_dialog.meeting = false"
			related="task"
			:lead-data="singleData.leadId"
			:task="singleData"
			:relatedId="taskId"
		></CreateMeeting>
		<!-- <CreateMeeting
		v-if="open_dialog.meeting"
			:drawer="open_dialog.meeting"
			:title-status="meeting_title"
			:get-meeting-data="editMeetingData"
			:attendies="attendieList"
			v-on:updateMeetingSuccess="refreshMeeting"
			v-on:close="open_dialog.meeting = false"
			related="lead"
			:related-id="lead_id"
		></CreateMeeting> -->

		<CreateReminder
			v-if="open_dialog.reminder"
			:drawer="open_dialog.reminder"
			:reminder-single-data="editReminderData.reminder"
			related="task"
			:relatedId="taskId"
			:attendies="reminderattendieList"
			v-on:close="open_dialog.reminder = false"
			v-on:updateRemindSuccess="refreshReminder"
		></CreateReminder>
		<CreateTask
			v-if="open_dialog.create_task"
			:task-data="singleData"
			:attendies="attendieList"
			:drawer="open_dialog.create_task"
			v-on:close="open_dialog.create_task = false"
			v-on:taskSuccess="singleTask"
			v-on:updateTask="refreshFunction()"
			related="task"
			:relatedId="taskId"
			:title-status="meeting_title"
		></CreateTask>
		<FullDialog v-if="taskDialog" :dialog="taskDialog" content-class="testdata">
			<template v-slot:title
				>Task {{ singleData.barcode }} Notes
				<v-spacer></v-spacer>
				<v-btn
					class="text-white"
					color="red darken-4"
					depressed
					tile
					:disabled="pageLoading"
					v-on:click="closeDialogRemark"
				>
					Close
				</v-btn>
			</template>
			<template v-slot:body>
				<AllNotesTab
					:relatedId="taskId"
					:relatedType="'task-notes'"
					create-url="task-note"
					:siggest-list="attendieList.attendies"
					get-url="task-note"
					:singleData="singleData"
				>
				</AllNotesTab>
			</template>
			<template v-slot:action>
				<v-btn class="text-white" color="red darken-4" depressed tile v-on:click="closeDialogRemark">
					Close
				</v-btn>
			</template>
		</FullDialog>
		<div class="chat-icon">
			<div class="call-btn-underlay" style="background-color: rgb(43, 149, 105)"></div>
			<v-icon style="font-size: 50px !important" color="green" small v-on:click="taskDialog = true"
				>mdi-chat
			</v-icon>
		</div>
	</v-sheet>
</template>
<script>
import CreateNote from "@/view/pages/leads/create/CreateNote";
import CreateTask from "@/view/pages/leads/create/CreateTask";
import CreateMeeting from "@/view/pages/leads/create/CreateMeeting";
import CreateReminder from "@/view/pages/leads/create/CreateReminder";
import ActivityTab from "@/view/pages/leads/components/ActivityTab";
import FullDialog from "@/view/components/FullDialog";
//import NotesTab from "@/view/pages/leads/components/NotesTab";
import MeetingTab from "@/view/pages/leads/components/MeetingTab";
import ReminderTab from "@/view/pages/leads/components/ReminderTab";
//import Checklist from "@/view/module/components/Checklist";
import ChecklistListing from "@/view/module/components/checklist-listing";
//import ChecklistNotes from "@/view/pages/leads/components/Checklist-Notes";
import { toSafeInteger } from "lodash";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import AllNotesTab from "@/view/pages/leads/components/NotesTab3";
import { GET, QUERY, PATCH } from "@/core/services/store/request.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Files from "@/view/components/Files";
import objectPath from "object-path";

export default {
	name: "Task-details",
	data() {
		return {
			search_open: false,
			open_dialog: {
				notes: false,
				meeting: false,
				reminder: false,
				add_company: false,
				create_task: false,
			},
			search: null,
			meeting_title: null,
			taskId: 0,
			singleData: {},
			checklists: [],
			logData: [],
			taskDialog: false,
			noteData: [],
			reminderData: [],
			viewType: false,
			remindersdata: [],
			editReminderData: {},
			meetingData: [],
			editMeetingData: {},
			statusData: [],
			listingStatus: [],
			reminderattendieList: {},
			editNoteData: null,
			related_type: "task",
			panel: [0],
			drawerCreate: false,
			/* taskTab: "overview", */
			task_action: false,
			open_details: false,
			assign_note: "Add Description",
			file_list: [],
			attendieList: {},
			list1: [
				{
					status_color: "orange",
				},
				{
					status_color: "green",
				},
				{
					status_color: "blue",
				},
			],
			page: 1,
		};
	},
	components: {
		CreateNote,
		CreateTask,
		CreateMeeting,
		CreateReminder,
		ActivityTab,
		FullDialog,
		//	NotesTab,
		MeetingTab,
		ReminderTab,
		ChecklistListing,
		AllNotesTab,
		Files,
	},
	methods: {
		open_task_drawer(name) {
			this.open_dialog.create_task = true;
			this.meeting_title = name;
		},
		closeDialogRemark() {
			this.taskDialog = false;
		},
		getStatusText() {
			if (this.singleData.status == 1) {
				return "In-Progress";
			}
			if (this.singleData.status == 2) {
				return "Completed";
			}
			if (this.singleData.status == 3) {
				return "Pending";
			}
		},
		refreshFunction() {
			this.singleTask(), this.statusFilter("all");
		},
		getStatusColor() {
			if (this.singleData.status == 1) {
				return "red";
			}
			if (this.singleData.status == 2) {
				return "green";
			}
			if (this.singleData.status == 3) {
				return "orange";
			}
			return "cyan";
		},
		open_meeting_drawer(name) {
			this.editMeetingData = {};
			this.attendieList = {};
			this.open_dialog.meeting = true;
			this.meeting_title = name;
		},
		getStatusActiveColor(status) {
			if (status == "In Progress") {
				return "border-red";
			}
			if (status == "Completed") {
				return "border-green";
			}
			if (status == "Pending") {
				return "border-orange";
			}
			return "cyan";
		},
		getActiveColor(data) {
			if (data == "low") {
				return "cyan";
			}
			if (data == "medium") {
				return "green";
			}
			if (data == "high") {
				return "orange";
			}
			if (data == "urgent") {
				return "blue";
			}
		},
		getReminderType(data) {
			if (data == 1) {
				return "One Time Reminder";
			}
			if (data == 2) {
				return "Daily Reminder";
			}
			if (data == 3) {
				return "Weekly Reminder";
			}
			if (data == 4) {
				return "Monthly Reminder";
			}
			if (data == 5) {
				return "Quarterly Reminder";
			}
			if (data == 6) {
				return "Half Yearly Reminder";
			}
		},
		doAction(action) {
			if (action == "edit") {
				this.open_dialog.create_task = true;
				this.meeting_title = "Update";
				return false;
			}
			this.pageLoading = true;
			this.$store
				.dispatch(PATCH, {
					url: `task-status/${action}/${this.taskId}`,
					data: this.holiday,
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Success ! Task status update successfully.",
						},
					]);
					this.singleTask();
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		pageTitle() {
			return "Task Details";
		},
		closeSerach() {
			(this.search_open = false), (this.search = "");
		},
		uploadFile(data) {
			const file_size = (data.size / 1024).toFixed(1) + "kb";
			const data_opj = { icon: "mdi-file-check-outline", name: data.name, size: file_size };
			this.file_list.push(data_opj);
		},
		selectFile() {
			this.$refs["uploadFile"].$refs["input"].click();
		},
		remove_file(index) {
			this.file_list.splice(index, 1);
		},
		singleTask() {
			this.$store
				.dispatch(GET, { url: `single-task/${this.taskId}` })
				.then((data) => {
					this.singleData = data.tasks;
					this.remindersdata = JSON.parse(data.tasks.remindersdata);
					this.attendieList = data.attendie[0];
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		taskActivity() {
			this.$store
				.dispatch(GET, { url: `task-log/${this.taskId}` })
				.then((data) => {
					this.logData = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getNotes() {
			this.$store
				.dispatch(QUERY, { url: `note`, data: { related_id: this.taskId, related_to: "task" } })
				.then((data) => {
					this.noteData = data.rows;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		editNote(id) {
			this.open_dialog.notes = true;
			this.$store
				.dispatch(GET, { url: `single-note/${id}` })
				.then((data) => {
					this.editNoteData = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getReminder() {
			this.$store
				.dispatch(QUERY, {
					url: `reminder`,
					data: { related_id: this.taskId, related_to: "task" },
				})
				.then((data) => {
					this.reminderData = data.rows;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		create_reminder() {
			this.editReminderData = {};
			this.reminderattendieList = {};
			this.open_dialog.reminder = true;
		},
		editReminderForm(id) {
			this.editReminderData = {};
			this.reminderattendieList = {};
			this.open_dialog.reminder = true;
			this.$store
				.dispatch(GET, { url: `single-reminder/${id}/${this.related_type}` })
				.then((data) => {
					this.editReminderData = data;
					this.reminderattendieList = data.attendie[0];
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getTaskMeeting() {
			this.$store
				.dispatch(QUERY, { url: `meeting-task`, data: { related_id: this.taskId, related_to: "task" } })
				.then((data) => {
					this.meetingData = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		editMeeting(id) {
			this.open_dialog.meeting = true;
			this.$store
				.dispatch(GET, { url: `single-meeting/${id}` })
				.then((data) => {
					this.editMeetingData = data.meeting;
					this.attendieList = data.attendie[0];
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getTaskSetting() {
			this.$store
				.dispatch(GET, { url: `task-setting` })
				.then((data) => {
					this.listingStatus = data.status;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		statusFilter(value) {
			this.pageLoading = true;
			this.$store
				.dispatch(QUERY, { url: `task`, data: { status: value, page: this.page } })
				.then((data) => {
					/* this.listingStatus = data.status; */
					if (this.page == 1) {
						this.statusData = [...data.tbody];
					} else {
						this.statusData = [...this.statusData, ...data.tbody];
					}
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		loadMoreTasks() {
			this.page++;
			this.statusFilter();
		},
		showLoadMoreBtn() {
			let alltasks = parseInt(objectPath.get(this.listingStatus, "0.status_count"));
			if (alltasks && alltasks > 15 && alltasks > this.statusData.length) {
				return true;
			} else {
				return false;
			}
		},
		getTaskChecklist() {
			this.$store
				.dispatch(GET, { url: `task/${this.taskId}/checklists` })
				.then((data) => {
					this.checklists = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		goBackData() {
			let filterQuery = this.$route.query;
			this.$router.push({
				name: "task",
				query: {
					...filterQuery,
					t: new Date().getTime(),
					viewType: this.viewType,
				},
			});
		},
		tabDetails(id) {
			this.$router.push({
				name: "task-detail",
				params: { id },
				query: { ...this.$route.query, tab: "checklist", t: new Date().getTime() },
			});

			this.taskId = id;
			this.singleTask();
			this.getTaskChecklist();
			this.getReminder();
			this.getTaskMeeting();
			this.taskActivity();
		},
		searchTask($event) {
			const keySearch = $event.target.value;

			if (keySearch !== "") {
				this.$store
					.dispatch(QUERY, { url: `task`, data: { search: keySearch } })
					.then((data) => {
						this.statusData = data.tbody;
					})
					.catch((error) => {
						console.log({ error });
					});
			}
		},
		updateSuccessNote() {
			this.open_dialog.notes = false;
			this.getNotes();
		},
		refreshMeeting() {
			this.getTaskMeeting();
		},
		refreshReminder() {
			this.getReminder();
		},
	},
	computed: {
		taskTab: {
			set(val) {
				let query = { ...this.$route.query };
				query.tab = val;
				if (val != this.taskTab) {
					this.$router.replace({ query });
				}
			},
			get() {
				return this.$route.query.tab || "task";
			},
		},
	},
	mounted() {
		if (!this.getPermission("task:view") && this.taskId > 0) {
			this.$router.replace({
				name: "task",
				query: { t: new Date().getTime() },
			});
		}
		this.singleTask();
		this.taskActivity();
		this.getNotes();
		this.getReminder();
		this.getTaskMeeting();
		this.getTaskSetting();
		this.statusFilter(4);
		this.getTaskChecklist();
		this.viewType = this.$route.query.viewType;
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Task", disabled: false, href: "/task" },
			{ text: "Detail", disabled: true },
		]);
	},
	beforeMount() {
		this.taskId = toSafeInteger(this.$route.params.id);
	},
};
</script>
